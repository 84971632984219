import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { connect } from 'react-redux'
import uuidv4 from 'uuid/v4';
import posed from 'react-pose';

import cards from "../assets/jsons/cards.json";


// Importing components
import Button from './Button';
import CardStatus from './CardStatus';
import CuotasPicker from './CuotasPicker';
import CuotasResults from './CuotasResults';
import SupportiveInfo from './SupportiveInfo';

import { getCategories, getCreditCards } from "../services/index";
import { selectQuotas, addQuotaToHistory } from "../actions";
import { fees } from "../utils/math";

const PoseCard = posed.div({
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '100%' : '64%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
})

const Card = styled(PoseCard)`
    z-index: 2;
    background: white;
    height: 87vh;
    width: calc(100vw - 2em);
    max-width: 450px;
    position: absolute;
    top: calc(0vh + 5.2em); /* <= resolves to the value that positions the card in the top */
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
    margin: 0 auto;
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
    font-size: 16px;
`;


class CardCuotas extends React.Component {

    constructor(props) 
    {
        super(props);
    
        this.state = {
            creditCardRate: 0,
            selectedQuotas: 0,
            quotasLimit: 0,
            quota: 2,
            quotaToPay: 0,
            cards: null,
            categories: null,
            isOpen: false,
            isSystemCardVisible: true
        }

        this.onNextClicked = this.handleSubmitEvent.bind(this);
        this.onIncrementClicked = this.handleIncrementEvent.bind(this);
        this.onDecrementClicked = this.handleDecrementEvent.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
    }

    componentDidMount()
    {
        Promise.all([ getCategories(), getCreditCards() ])
            .then(values => {

                let categories = values[0].find(category => category.id === this.props.app.selectedCategory);
                let quotasLimit = (categories) ? categories.quotesQuantity : 12;

                let cards = values[1].find(card => card.id === this.props.app.selectedCreditCard);
                let creditCardRate = (cards) ? cards.interestRate : 24;

                this.setState({ 
                    quotasLimit, 
                    creditCardRate, 
                    categories: values[0],
                    cards: values[1] 
                })
            })
    }

    // shouldComponentUpdate(nextProps, nextState)
    // {
    //     return nextProps.isOpen || (!nextProps.isOpen && this.state.isOpen);
    // }

    componentDidUpdate(prevProps)
    {
        if (this.props.isOpen !== prevProps.isOpen) {
 
            let quotaToPay = fees(2, this.state.creditCardRate, this.props.app.amount)

            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen,
                quota: 2,
                quotaToPay
            });
        }

        if (this.props.app.selectedCategory !== prevProps.app.selectedCategory) {
            let category = this.state.categories.find(category => category.id === this.props.app.selectedCategory);
            let quotasLimit = (category) ? category.quotesQuantity : 12;
            this.setState({ quotasLimit });
        }

        // console.log('this.props.app.selectedCreditCard', this.props.app.selectedCreditCard)
        // console.log('prevProps.app.selectedCreditCard', prevProps.app.selectedCreditCard)

        if(this.props.app.selectedCreditCard !== prevProps.app.selectedCreditCard) {

            let card = this.state.cards.find(card => card.id === this.props.app.selectedCreditCard);
            let creditCardRate = (card) ? card.interestRate : 24;
            this.setState({ creditCardRate });
        }
    }

    handleIncrementEvent = () => {

        if (Number(this.state.quota) === Number(this.state.quotasLimit)) return;

        let quota = this.state.quota + 1;

        let quotaToPay = fees(quota, this.props.app.interestRate, this.props.app.amount)
        this.setState({ quota, quotaToPay })
    }
    
    handleDecrementEvent = () => {

        if (Number(this.state.quota) === 2) return;

        let quota = this.state.quota - 1;

        let quotaToPay = fees(quota, this.props.app.interestRate, this.props.app.amount)
        this.setState({ quota, quotaToPay })
    }

    handleSubmitEvent = () => {

        this.props.selectQuotas(this.state.quota)

        let quota = {
            id: uuidv4(),
            selectedCategory: this.props.app.selectedCategory,
            amount: this.props.app.amount,
            quota: this.state.quota,
            interestRate: this.state.creditCardRate,
            created_at: new Date()
        };

        this.props.addQuotaToHistory(quota);
        this.props.stepHandler(5)

        ReactGA.event({
            category: 'Journey',
            action: 'Completed a Calculation',
            label: 'Button',
        });
    }

    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false })
        this.props.stepHandler(4)
    }

    render() {
        const { isOpen, isSystemCardVisible, quotaToPay, creditCardRate, quota, quotasLimit } = this.state;

        const { amount } = this.props.app;
        const labelValue = `${this.props.app.quota} cuotas`;

        return (
            <Card pose={isOpen ? 'open' : 'closed'}>
                <CardStatus 
                    isVisible={isSystemCardVisible} 
                    labelContent="Cuotas" 
                    labelValueContent={labelValue ? labelValue : ''}
                    onClick={this.onSystemCardClick}  />
                <CardTitle>{this.props.cardTitle}</CardTitle>
                <CuotasPicker
                    selectedQuota={quota}
                    quotaLimit={quotasLimit}
                    onIncrement={this.onIncrementClicked}
                    onDecrement={this.onDecrementClicked} />
                <CuotasResults 
                    amountToCalculate={amount} 
                    cuotaToPay={quotaToPay} />
                <SupportiveInfo 
                    annualInterestFee={creditCardRate} 
                    monthInterestFee={creditCardRate / 12} />
                <Button 
                    textButton="Guardar Cálculo" 
                    onClickHandle={this.onNextClicked} />
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState
});

export default connect(
    mapStateToProps,
    { selectQuotas, addQuotaToHistory }
)(CardCuotas);