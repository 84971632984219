import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import ReactGA from 'react-ga'
import { connect } from 'react-redux'

//Importing components
import InputComponent from './Input';
import CardStatus from './CardStatus';
import CreditCardSelector from './CreditCardSelector';

// Actions
import { getCreditCards } from "../services/index";
import { selectCategory, selectCreditCard, selectRate } from "../actions";
import { STORAGE_KEY_SELECTED_RATE } from '../utils/keys'

const PoseCard = posed.div({
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '100%' : '72%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
})

const Card = styled(PoseCard)`
    z-index: 3;
    background: white;
    height: 87vh;
    width: calc(100vw - 2em);
    max-width: 450px;
    position: absolute;
    top: calc(0vh + 5.2em); /* <= resolves to the value that positions the card in the top */
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
    margin: 0 auto;

    // @media(max-width: 500px) {
    //     top: calc(5.2em + 100%) !important;
    // }
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
`;

class CardCreditCard extends React.Component {

    constructor() {
        super()

        this.state = {
            filterBy: '',
            cards: null,
            isOpen: false,
            isSystemCardVisible: true
        }

        this.onClick = this.handleOnClickEvent.bind(this);
        this.onInputChange = this.handleOnInputChange.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
        this.resetInput = this.handleResetInput.bind(this);
    }

    componentDidMount() {
        getCreditCards()
            .then(creditCardsJson => {
                if (creditCardsJson) {
                    this.setState({ cards: creditCardsJson })
                }
            })
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.isOpen || (!nextProps.isOpen && this.state.isOpen);
    // }

    componentDidUpdate(prevProps) {

        if (this.props.isOpen !== prevProps.isOpen) {

            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen
            });
        }
    }

    handleOnInputChange = (event) => {
        let filterBy = event.target.value
        this.setState({ filterBy });
    }


    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false })
        this.props.stepHandler(3)
        localStorage.setItem(STORAGE_KEY_SELECTED_RATE, 0)
    }


    /**
     * Event that handles when the user selects a Credit Card
     */
    handleOnClickEvent = (cardId) => {

        this.props.selectCreditCard(cardId);

        let card = (this.state.cards) && this.state.cards.find(card => card.id === cardId);
        this.props.selectRate(card.interestRate);

        this.setState({
            isOpen: !this.props.isOpen,
            isSystemCardVisible: true
        });

        this.props.stepHandler(4);

        ReactGA.event({
            category: 'Journey',
            action: `Selected Card: ${this.state.cards.find(card => card.id === cardId).name}`,
            label: 'Card Button',
            value: 1
        });
    }


    handleResetInput = () => {
        this.setState({ filterBy: '' })
    }


    render() {
        const { filterBy, isOpen, isSystemCardVisible, cards } = this.state;

        const cardLabel = (this.state.cards) &&
            this.state.cards.find(card => {
                return Number(card.id) === Number(this.props.app.selectedCreditCard)
            })

        return (
            <Card pose={isOpen ? 'open' : 'closed'}>
                <CardStatus
                    isVisible={isSystemCardVisible}
                    labelContent="Tarjeta"
                    labelValueContent={cardLabel ? cardLabel.name : ''}
                    onClick={this.onSystemCardClick} />
                <CardTitle>{this.props.cardTitle}</CardTitle>
                <InputComponent
                    alphaNumInput
                    placeholder='Intenta "Clásica"'
                    onChange={this.onInputChange}
                    value={this.state.filterBy} />
                <CreditCardSelector
                    cards={cards}
                    filterBy={filterBy}
                    onClick={this.onClick}
                    resetInput={this.resetInput.bind(this)} />
            </Card>
        )
    }
}
const mapStateToProps = (state) => ({
    app: state.cardState
})

export default connect(
    mapStateToProps,
    { selectCategory, selectCreditCard, selectRate }
)(CardCreditCard);