import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'
import { connect } from 'react-redux'
import styled from 'styled-components';
import Whirligig from 'react-whirligig'
import html2canvas from 'html2canvas';
import Alert from 'react-s-alert'

// Importing components
import Header from './Header';
import Button from './Button';
import EmptyHistory from './empty-states/EmptyHistory'

import { fees } from "../utils/math";
import { getQuotaHistory, resetState, deleteQuota } from "../actions";


const CuotasHistoryWrapper = styled.div`
    z-index: 1;
    width: calc(100vw - 2em);
    margin: 0 auto;
    max-width: 450px;
    position: absolute;
    top: calc(0vh + 4.2em); /* <= resolves to the value that positions the card in the top */
    left:1em;
    right:1em;
    display: ${props => props.isHidden ? "none" : "block"};
    
    & > :last-child{
        margin-top:1.5em;
    }
`;

const HistoryTitle = styled.h2`
    color: #2F692F;
    text-align: center;
    font-family: 'Trebuchet';
`;

const HistoryItem = styled.div`
    background-image: linear-gradient(1deg, #50B044, #66C657);
    border-radius: 7px;
    padding: 1em 1em;
    color: #2F692F;
    font-size: 1rem;
    font-family: 'Trebuchet';
    max-width: 400px;


    & strong{
        color: white;
    }

    & button {
       
        background: linear-gradient(0deg, hsla(112, 87%, 93%, 0.85), hsl(0, 0%, 100%));
        color: #2F692F;
        box-shadow: none;
        
        &.delete-btn{
            color:#2F692F;
            width:70%;
            padding-top:0.6em;
            padding-bottom:0.6em;
            font-size:15px;
            margin-top: 16px;
            margin-right: 10px;
            cursor: pointer;
        }

        &.share-btn{
            color:#2F692F;
            width:70%;
            padding-top:0.6em;
            padding-bottom:0.6em;
            font-size:15px;
            margin-top: 16px;
            cursor: pointer;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
`;

class CuotasFinalResults extends Component {

    constructor() {
        super();

        this.state = {
            quotaHistory: [],
            showCanvas: true,
            isSafari: false
        }

        this.onNewQuotaClicked = this.handleOnNewQuotaClicked.bind(this);
        this.onShareClicked = this.handleOnSharedClicked.bind(this);
        this.onDeleteClicked = this.handleOnDeleteClicked.bind(this);
        this.onCopyClicked = this.handleQuotaCopied.bind(this);

        this.html2CanvasOptions = { logging: false }

        // let isSafari = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        // this.setState({isSafari});
    }

    componentDidMount() {

        this.setState({
            quotaHistory: this.props.history.quotaHistory
        })
    }

    componentDidUpdate(prevProps) {
        if (this.state.quotaHistory.length !== prevProps.history.quotaHistory.length) {
            this.setState({
                quotaHistory: this.props.history.quotaHistory
            })
        }
    }

    /**
     * Handles the navigation flow to start from the beginning,
     * by using stepHandler parent function.
     */
    handleOnNewQuotaClicked = () => {
        this.props.resetState();
        this.props.stepHandler(1)
    }

    /**
     * Currently downloads an custom image of the selected quota.
     */
    handleOnSharedClicked = (quote) => {

        let ref = document.querySelector(`#id-${quote.id}`).cloneNode(true);
        ref.style.position = 'absolute';
        ref.style.top = '-100vh';
        ref.style.left = '-100vw';

        let header = renderToString(<Header />);
        ref.insertAdjacentHTML('beforeend', header);

        ref.querySelectorAll('button')
            .forEach(button => {
                ref.querySelector(`#btn-container-${quote.id}`).removeChild(button);
            });

        document.body.appendChild(ref);

        if (this.state.isSafari) {
            this.onCopyClicked(ref.textContent);

            Alert.info('Se ha copiado al portapapeles un resumen de su cálculo.', {
                position: 'top',
                effect: 'stackslide',
                timeout: 2000
            });
        } else {
            html2canvas(ref, this.html2CanvasOptions)
                .then(function (canvas) {

                    let id = quote.id.split('-')[0];

                    let domImage = document.createElement('a');
                    domImage.href = canvas.toDataURL('image/png');
                    domImage.target = '_blank';
                    domImage.download = `calculo_cuota_${id}.png`;

                    document.body.appendChild(domImage);
                    domImage.click();

                    document.body.removeChild(ref)
                    document.body.removeChild(domImage)
                })
                .catch(err => {
                    console.error('Error: ', err);
                });
        }
    }

    handleQuotaCopied = text => {
        const el = document.createElement('textarea');
        el.value = text.replace(/ {2}/g,' ');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };


    handleOnDeleteClicked = (id) => {
        this.props.deleteQuota(id)
    }

    /**
     * Formats a given number to es-419, which is the latin american 
     * format for currency.
     */
    _format = (number) => new Intl.NumberFormat('es-419', { style: 'decimal' }).format(number)

    /**
     * Calculates the monthly fee given the quotas, interest rate % and amount
     */
    _fee = (quote) => fees(quote.quota, quote.interestRate, quote.amount)


    render() {
        const { isOpen } = this.props;

        return (
            <CuotasHistoryWrapper isHidden={!isOpen}>
                <HistoryTitle>Historial de cálculos</HistoryTitle>
                {
                    this.state.quotaHistory.length > 0 ?
                        <Whirligig
                            visibleSlides={1.15}>
                            {
                                this.state.quotaHistory
                                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                    .map(quote => {
                                        return (
                                            <HistoryItem id={`id-${quote.id}`} key={quote.id}>
                                                Por <strong>RD$ {this._format(quote.amount)}</strong> <br /> vas a pagar <strong>RD$ {this._format(this._fee(quote))}/ mes</strong> <br />en <strong>{quote.quota} cuotas</strong> <br /> a una <strong>tasa anual de un {quote.interestRate}%</strong> ({(quote.interestRate / 12).toFixed(2)}%/mes).
                                                <ButtonContainer id={`btn-container-${quote.id}`}>
                                                    <Button className="delete-btn" textButton="Borrar" onClickHandle={this.onDeleteClicked.bind(this, quote.id)} />
                                                    <Button className="share-btn" textButton={this.state.isSafari ? 'Copiar' : 'Descargar'}  onClickHandle={this.onShareClicked.bind(this, quote)} />
                                                </ButtonContainer>
                                            </HistoryItem>
                                        );
                                    })
                            }
                        </Whirligig>
                        : (<EmptyHistory />)
                }

                <Button textButton="Calcular otro monto" onClickHandle={this.onNewQuotaClicked} />
            </CuotasHistoryWrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState,
    history: state.historyState
})

export default connect(
    mapStateToProps,
    { getQuotaHistory, resetState, deleteQuota }
)(CuotasFinalResults);