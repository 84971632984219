import * as types from './ActionTypes'


export const selectCategory = categoryId => ({
  type: types.SELECTED_CATEGORY,
  payload: categoryId
})

export const selectAmount = amount => ({
  type: types.SELECTED_AMOUNT,
  payload: amount
})

export const selectQuotas = quotas => ({
  type: types.SELECTED_QUOTAS,
  payload: quotas
})

export const selectRate = rate => ({
  type: types.SELECTED_RATE,
  payload: rate
})

export const selectCreditCard = creditCardId => ({
  type: types.SELECTED_CREDIT_CARD,
  payload: creditCardId
})


export const resetState = rate => ({
  type: types.RESET_STATE,
})


export const getQuotaHistory = () => ({
  type: types.GET_QUOTA_HISTORY
})


export const addQuotaToHistory = quota => ({
  type: types.ADD_QUOTA_HISTORY,
  payload: quota
})


export const deleteQuota = quotaId => ({
  type: types.DELETE_QUOTA_HISTORY,
  payload: quotaId
})
