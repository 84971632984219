import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import posed from 'react-pose';

// Importing components
import InputComponent from './Input';
import CategorySelector from './CategorySelector';
import CardStatus from './CardStatus';

// Importing actions
import { getCategories } from "../services/index";
import { selectCategory, selectRate } from "../actions";
import { STORAGE_KEY_SELECTED_CATEGORY } from '../utils/keys'

const PoseCard = posed.div({
    // draggable: 'y',
    // dragBounds: { top: '-72%', bottom: '0%' },
    open: {
        top: 'calc(5.2em + 0%)',
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    },
    closed: {
        top: () => {
            let isSmallDevice = window.matchMedia('(max-width: 340px)').matches;
            return `calc(5.2em + ${ isSmallDevice ? '100%' : '72%' })`;
        },
        transition: { type: 'spring', stiffness: 75, velocity: 10, mass: 0.5 }
    }
})


const Card = styled(PoseCard)`
    z-index: 4;
    background: white;
    width: calc(100vw - 2em);
    max-width: 450px;
    min-height: 100vh;
    position: absolute;
    top: calc(5.2em + 0%);
    left:1em;
    right:1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    padding-left:1em;
    padding-right:1em;
    overflow: auto;
    margin: 0 auto;

    & .category-btn{
        margin-top:0.75em;
    }
`;

const CardTitle = styled.h2`
    color: #66BB66;
    text-align: center;
    font-family: 'Trebuchet';
    font-weight: normal;
    font-size: 1.1rem;
`;

class CardCategory extends React.Component {

    constructor() {
        super();

        this.state = {
            filterBy: '',
            categories: null,
            isOpen: false,
            isSystemCardVisible: true
        }

        this.onClick = this.handleOnClickEvent.bind(this);
        this.onInputChange = this.handleOnInputChange.bind(this);
        this.onSystemCardClick = this.handleSystemCardClick.bind(this);
        this.resetInput = this.handleResetInput.bind(this);

        // card events
        this.onDragEnd = this.handleOnDragEnd.bind(this);
        this.onDrag = this.handleOnDrag.bind(this);

        this.card = React.createRef();
        this.inputComponent = React.createRef();
    }

    componentDidMount()
    {
        getCategories()
            .then(categoriesJson => {
                if(categoriesJson) {
                    this.setState({ categories: categoriesJson })
                }
            })
    }

    componentDidUpdate(prevProps) 
    {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
                isSystemCardVisible: !this.props.isOpen
            });
        }
    }

    handleOnClickEvent = (categoryId) => {

        this.props.selectCategory(categoryId);
        
        this.setState({
            isOpen: false,
            isSystemCardVisible: true
        });

        this.props.stepHandler(3);

        ReactGA.event({
            category: 'Journey',
            action: `Selected Category: ${this.state.categories.find(category => category.id === categoryId).name}`,
            label: 'Category Button',
            value: 1
          });
    }

    handleOnInputChange = (event) => {
        let filterBy = event.target.value
        this.setState({ filterBy });
    }

    handleSystemCardClick = () => {
        this.setState({ isOpen: true, isSystemCardVisible: false})
        this.props.stepHandler(2)
        localStorage.setItem(STORAGE_KEY_SELECTED_CATEGORY, 0)
    }

    handleResetInput = () => {
        this.setState({ filterBy: '' })
    }

    handleOnDrag = (y) => {
        console.log('onDrag', y)

        // if(y === 0 ) return;

        // let scrollPercentage = Math.abs(y);

        // get the value in number type
        // console.log({scrollPercentage})
        // this.card.current.style.top = `calc(5.2em + ${scrollPercentage}%)`;
    }

    handleOnDragEnd = (ev) => {
        console.log('onDragEnd')

        let { clientY, pageY, screenY } = ev.changedTouches[0];

        console.log({ clientY, pageY, screenY　})
        // console.log(this.card.current)
        // this.card.current.style.top = '5%';
        // let { clientY } = ev.changedTouches[0];
        // console.log('dragend', clientY)
        // if(screenY )

        // this.setState({ isOpen: true, isSystemCardVisible: false})
        // this.props.stepHandler(2)
        // localStorage.setItem(STORAGE_KEY_SELECTED_CATEGORY, 0)

        // check clientY if less than 50% make pose close
    }


    render() {
        const { filterBy, isOpen, isSystemCardVisible } = this.state;
        const cardLabel = (this.state.categories) &&
            this.state.categories.find(category => {
                return Number(category.id) === Number(this.props.app.selectedCategory)
            })

        return (
            <Card 
                ref={this.card}
                pose={isOpen ? 'open' : 'closed'}>
                <CardStatus 
                    isVisible={isSystemCardVisible} 
                    labelContent="Tipo de comercio" 
                    labelValueContent={cardLabel ? cardLabel.name : ''} 
                    onClick={this.onSystemCardClick}
                    // onDragStart={this.onDrag}
                    />
                <CardTitle>{this.props.cardTitle}</CardTitle>
                <InputComponent
                    alphaNumInput 
                    placeholder='Busca tu categoría de comercio' 
                    onChange={this.onInputChange} 
                    value={this.state.filterBy} />
                <CategorySelector
                    categories={this.state.categories}
                    filterBy={filterBy} 
                    onClick={this.onClick.bind(this)} 
                    resetInput={this.resetInput.bind(this)} />
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    app: state.cardState
})

export default connect(
    mapStateToProps,
    { selectCategory, selectRate}
)(CardCategory);