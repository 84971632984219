import React from 'react';
import styled from 'styled-components';

const HeaderLogo = styled.div`
    text-align: center;
    margin-top: 1em;
`

const Header = (props) => {

    let handleClick = (event) => {
        event.preventDefault();
        props.onClick(1);
    }

    return(
        <HeaderLogo onClick={handleClick}>
            <img src="images/cuotas-logo.png" alt="Logo cuotas BHD León" />
        </HeaderLogo>
    )
}



export default Header;