import React from 'react';
import styled from 'styled-components';


const NumKeyboardComp = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-template-rows:1fr 1fr 1fr 1fr;
    background-image: radial-gradient(circle at center, #C8C8C8, transparent 65%);
    grid-gap:1px;
`;

const Key = styled.button`
    border: none;
    text-align: center;
    padding-top:15px;
    padding-bottom:15px;
    color: #66BB66;
    font-weight: normal;
    font-size: 20px;
    background-color:white;
    outline:none;
    margin:0;
    touch-action: manipulation;

    @media(max-width: 500px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &.key0 {
        grid-column: 2/3;
        grid-row: 4/5;
    }
    &.keyBackspace {
        grid-column: 3/4;
        grid-row: 4/5;
        position:relative
        img{
            position:absolute;
            left:32px;
            top:9px;
        }
    }
`;

const NumKeyboard = (props) => {
   let keys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    return(
        <NumKeyboardComp>
            {
                keys.map(number => {
                    return (
                        <Key 
                            key={number} 
                            className={`key${number}`} 
                            onClick={props.onClick.bind(this, number)}>
                            {number}
                        </Key>)
                })
            }
            <Key className="keyBackspace" onClick={props.onClick.bind(this, 54)}>
                <img src="/images/backspace-symbol.svg" alt="Borrar"/>
            </Key>
            <Key className="emptyKey"></Key>
        </NumKeyboardComp>
    )
};

export default NumKeyboard;