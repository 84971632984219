import React from 'react';
import styled from 'styled-components';

import EmptyState from "./empty-states/EmptyCreditCard";

import { sanitize } from '../utils/strings'

const CreditCardsWrapper = styled.div`
    display:flex;
    flex-direction: column;
    max-height: calc(100vh - 13.2em);
    overflow-y: scroll;
    padding-bottom: 10rem;
    align-items: stretch;
`;

const CreditCardType = styled.button`
    background-color: transparent;
    border: none;
    padding: 8px;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ddd;
    min-height: 76px;
`;


const CreditCardText = styled.div`
    text-align: center;
    font-size: 14px;
    margin-top: 7px;
    margin-left: 1em;
    color: #555555;
    hyphens: auto;
    webkit-hyphens: auto;
    ms-hyphens: auto;
    font-weight: bold;
`;


class CreditCardSelector extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            filterBy: '',
            filteredCards: []
        }
    }

    componentDidMount()
    {
        // let sortedCards = this.props.cards ? this.props.cards.sort((a,b) => (a.order > b.order) ? -1 : 1) : []

        // this.setState({
        //     filteredCards: sortedCards
        // })
    }

    // shouldComponentUpdate(nextProps, nextState)
    // {
    //     return (nextProps.filterBy !== this.state.filterBy) || (nextProps.cards ? true : false);
    // }

    componentDidUpdate(prevProps, prevState, snapshot)
    {

        if(this.props.cards !== prevProps.cards) {

            let sortedCards = this.props.cards
            ? this.props.cards.sort((a,b) => (a.order > b.order) ? -1 : 1)
            : []

            this.setState({ filteredCards: sortedCards })
        }

        if (this.props.filterBy !== prevProps.filterBy) {

            let filterBy = this.props.filterBy.toLowerCase();

            let filteredCards =
                this.props.cards
                .filter(card => {

                    // if nothing is passed, then render as usual
                    if (!filterBy) return true;

                    // if filterBy matches a name, go through
                    if (sanitize(card.name).toLowerCase().includes(filterBy))
                        return true;

                    let containsSynonym = false;

                    // if it doesn't match, then try to filter by synonyms
                    if(card.synonyms) {
                        card.synonyms
                        .split(',')
                        .forEach(element => {
                            if (sanitize(element).toLowerCase().includes(filterBy)) {
                                containsSynonym = true;
                            }
                        });
                    }

                    return containsSynonym;
                })
                .sort((a,b) => (a.order > b.order) ? -1 : 1)

            this.setState({
                filterBy: filterBy,
                filteredCards: filteredCards
            })
        }
    }

    render() {

        if(this.state.filteredCards.length === 0) {
            return (<EmptyState resetInput={this.props.resetInput} />)
        }

        return (
            <CreditCardsWrapper>
                {
                    this.state.filteredCards.length ? (
                        this.state.filteredCards.map(cardItem => {
                            return (
                                <CreditCardType
                                    key={cardItem.id}
                                    card={cardItem.id}
                                    className={`category${cardItem.id}`}
                                    onClick={this.props.onClick.bind(this, cardItem.id)}>
                                    <img src={`https://api.cuotasbhdleon.com${cardItem.icon.url}`} alt={`Tarjeta ${cardItem.name}`} width="89"/>
                                    <CreditCardText>{cardItem.name}</CreditCardText>
                                </CreditCardType>
                            )
                        })
                    ) : (
                        <EmptyState resetInput={this.props.resetInput} />
                    )
                }
            </CreditCardsWrapper>
        )
    }

}


export default CreditCardSelector;
