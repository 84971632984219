import {
  GET_QUOTA_HISTORY ,ADD_QUOTA_HISTORY, DELETE_QUOTA_HISTORY
} from '../actions/ActionTypes'

import { 
  STORAGE_KEY_HISTORY,
} from "../utils/keys";

// set initial state of the quota history
let quotaHistoryState =  JSON.parse(localStorage.getItem(STORAGE_KEY_HISTORY)) || []

const initialState = {
  quotaHistory: quotaHistoryState
}


const quoteReducer = (state = initialState, action) => {

  switch (action.type) {

    case GET_QUOTA_HISTORY:
      return state.quotaHistory;

    case ADD_QUOTA_HISTORY:
      state.quotaHistory.push(action.payload)
      localStorage.setItem(STORAGE_KEY_HISTORY, JSON.stringify(state.quotaHistory))
      return { ...state }

    case DELETE_QUOTA_HISTORY:
      state.quotaHistory = state.quotaHistory.filter(quota => quota.id !== action.payload)
      localStorage.setItem(STORAGE_KEY_HISTORY, JSON.stringify(state.quotaHistory))
      return { ...state }

    default:
      return state
  }

}

export default quoteReducer;