import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { createStore } from "redux";
import { Provider } from "react-redux";
import styled from 'styled-components';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';

import rootReducers from "./reducers"

// Importing components
import Alert from 'react-s-alert';
import Header from './components/Header';
import CardAmount from './components/CardAmount';
import CardCategory from './components/CardCategory';
import CardCreditCard from './components/CardCreditCard';
import CardCuotas from './components/CardCuotas';
import CuotasFinalResults from './components/CuotasFinalResults';

import { 
  STORAGE_KEY_SELECTED_CATEGORY, STORAGE_KEY_HISTORY
} from "./utils/keys";


import './assets/custom-font.css';
import './assets/lightbox.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

let Carousel = require('react-responsive-carousel').Carousel;

const CalculatorWrapper = styled.div`
/* Universal universal border box fix */
  box-sizing: border-box;
  *,
  ::before,
  ::after{
    box-sizing:inherit;
  }
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  right:0;
  overflow: hidden;
  font-size: 1rem;
`;


const store = createStore(rootReducers)

class Calculator extends Component {

  constructor() {
    super();

    this.state = {
      step: 1
    }

    ReactGA.initialize('UA-89345510-3');
    this.changeStep = this.handleStepChange.bind(this);
  }

  componentDidMount() {

    let quotaHistory = JSON.parse(localStorage.getItem(STORAGE_KEY_HISTORY)) || [];
    let selectedCagegory = localStorage.getItem(STORAGE_KEY_SELECTED_CATEGORY);

    if(selectedCagegory && Number(selectedCagegory) !== 0 && quotaHistory.length === 0) {
      this.setState({ step: 1 })
    } 
    else if(quotaHistory.length > 0) {
        this.setState({ step: 5 })
    }

    let isSafari = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (isSafari && (!localStorage.getItem('pwa.tutorial') || Boolean(localStorage.getItem('pwa.tutorial')) === false)) {

      const content = (
        <Fragment>
          <p className="carousel-title">Instala la aplicación a tu celular</p>
          {/* galllery */}
          <Carousel 
            showArrows={true}
            showThumbs={false}>
            <div>
                <img src="/images/pwa-tutorial/step_1.jpg" alt="step 1" />
            </div>
            <div>
                <img src="/images/pwa-tutorial/step_2.jpg" alt="step 2" />
            </div>
            <div>
                <img src="/images/pwa-tutorial/step_3.jpg" alt="step 3" />
            </div>
            <div>
                <img src="/images/pwa-tutorial/step_4.jpg" alt="step 4" />
            </div>
          </Carousel>
          <button className="lightbox-close-btn" onClick={PopupboxManager.close}>OK</button>
        </Fragment>
      )
      PopupboxManager.open({ content })

      localStorage.setItem('pwa.tutorial', true)

      return;
    }
    
  }


  
  /**
   * TODO: doc
   */
  handleStepChange = (step) => {
    this.setState({ step })
  }

  render() {
    return (
      <Provider store={store}>
        <CalculatorWrapper className="App">

          <Header onClick={this.changeStep} />

          <CardAmount 
            cardTitle="¿Qué monto deseas calcular en cuotas?" 
            isOpen={this.state.step  === 1} 
            stepHandler={this.changeStep} />

          <CardCategory 
            cardTitle="Selecciona la categoría de comercio" 
            isOpen={this.state.step  === 2} 
            stepHandler={this.changeStep} />

          <CardCreditCard 
            cardTitle="Selecciona el tipo de tarjeta con la que solicitarás tus Cuotas" 
            isOpen={this.state.step  === 3} 
            stepHandler={this.changeStep} />

          <CardCuotas
             cardTitle="¿En cuántas cuotas deseas pagar?" 
             isOpen={this.state.step  === 4} 
             stepHandler={this.changeStep}/>

          <CuotasFinalResults 
            isOpen={this.state.step  === 5} 
            stepHandler={this.changeStep} />

          <Alert stack={{ limit: 1 }} />
          
          <PopupboxContainer />

        </CalculatorWrapper>
      </Provider>
    );
  }
}

export default Calculator;