import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width:250px;
    margin-left:auto;
    margin-right:auto;
`;

const MinusButton = styled.button`
    background-image:linear-gradient(to top, #DBDBDB, white);
    border:none;
    outline:none;
    box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.25);
    border-radius:8px;
    width:56px;
    height:56px;
    font-size:2em;
    touch-action: manipulation;
`;

const PlusButton = styled.button`
    background-image:linear-gradient(to top, #DBDBDB, white);
    border:none;
    outline:none;
    box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.25);
    border-radius:8px;
    width:56px;
    height:56px;
    font-size:2em;
    touch-action: manipulation;
`;

const InputCuotas = styled.input`
    padding-top:.4em;
    padding-bottom:.4em;
    padding-left:.8em;
    padding-right:.8em;
    font-size: 2em;
    width:90px;
    text-align:center;
    outline:none;
    background-color:transparent;
    border:none;
`;

const CuotasQtyLimit = styled.span`
    background-color: rgb(119, 119, 119);
    color: white;
    border-radius:4px;
    font-size:.9em;
    padding: 0.2em 1em;
    font-family: Trebuchet;
`;

const CuotasPicker = (props) =>{
    const { quotaLimit, onIncrement, onDecrement, selectedQuota } = props;
    return(
        <Wrapper>
            <MinusButton onClick={onDecrement}>-</MinusButton>
            <InputCuotas readOnly value={selectedQuota} />
            <PlusButton onClick={onIncrement}>+</PlusButton>
            <CuotasQtyLimit>Hasta { quotaLimit } cuotas</CuotasQtyLimit>
        </Wrapper>
    )
};

export default CuotasPicker;